<template>
    <div class="fond_bg">
        <van-nav-bar
                placeholder
                fixed
                left-arrow
                :border='false'
                z-index="99"
                @click-left="$router.go(-1)">
            <template #left>
                <van-icon name="arrow-left" size="25" color="#0A1C33"/>
            </template>
        </van-nav-bar>
        <div class="fond-text">
            <p class="fond-title">请选择您喜欢的类型</p>
            <p class="fond-subtitle">为您推荐喜欢的内容</p>
        </div>
        <p class="boy_def">
            <img src="../../assets/images/boy_selected.png" alt="" class="boy_def1" v-if="hobbyList.issex =='1'"
                 @click="boyClick">
            <img src="../../assets/images/boy_def1.png" alt="" class="boy_def1" v-else @click="boyClick">
        </p>
        <p class="boy_def">
            <img src="../../assets/images/girl_selected.png" alt="" class="boy_def1" v-if="hobbyList.issex =='2'"
                 @click="girlClick">
            <img src="../../assets/images/girl_def2.png" alt="" class="boy_def1" v-else @click="girlClick">
        </p>
        <!--        喜好选择-->
        <van-action-sheet v-model="show" title="请选择您的阅读喜好" close-icon="success" @cancel="cancelClick"
                          class="conter-header">
            <div class="content">
                <!-- active-->
                <template v-for="item in hobbyList.hobby">
                    <p :class="item.isselect =='1'?'active':''" @click="hobbyClick(item)">{{item.title}}</p>
                </template>
            </div>
        </van-action-sheet>
        <div class="read_trend_bg">
            <img src="../../assets/images/read_trend_bg.png" alt="">
        </div>
    </div>
</template>

<script>
    export default {
        name: "Fond",
        data() {
            return {
                show: false,
                hobbyList: [],
                checkBox: [],//选中的内容
            }
        },
        created() {
            this.hobby()
        },
        methods: {
            hobbyClick(item) {
                if (!item.status && item.isselect == '0') {
                    item.isselect = '1'
                    item.status = true;
                    this.checkBox.push(item);
                } else {
                    item.isselect = '0'
                    item.status = false;
                    this.checkBox = this.checkBox.filter(item => {
                        return item.status
                    })
                }
            },
            hobby() {
                this.$axios.post('/api/user/hobby')
                    .then(res => {
                        this.hobbyList = res.data.data
                        this.hobbyList.hobby.forEach(item => {
                            item.status = false
                            if (item.isselect == '1') {
                                item.status = true
                                this.checkBox.push(item);
                            }
                        })
                    })
            },
            boyClick() {
                this.hobbyList.issex = '1'
                this.show = true
            },
            girlClick() {
                this.hobbyList.issex = '2'
                this.show = true
            },
            cancelClick() {
                this.show = false
                if (this.checkBox.length == 0) return
                let id = '';
                this.checkBox.forEach(item => {
                    id = id.concat(item.id) + ','
                })
                id = id.substring(0, id.lastIndexOf(','));
                this.$axios.post('/api/user/save_hobby', {
                    issex: this.hobbyList.issex,
                    cids: id,
                })
                    .then(res => {
                        this.$toast.success('设置成功')
                        this.hobby()
                    })
            }
        }
    }
</script>

<style scoped>
    .fond_bg {
        position: relative;
        height: 100vh;
    }

    .read_trend_bg {
        position: absolute;
        bottom: 0;
    }

    .read_trend_bg > img {
        width: 100%;
        height: 200px;
    }

    .active {
        background: #4D77FD !important;
        color: #fff !important;
    }

    .content {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        padding: 40px;
    }

    .content > p {
        width: 180px;
        height: 60px;
        background: rgba(242, 245, 248, 1);
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 26px;
        font-weight: 400;
        color: rgba(96, 98, 102, 1);
        line-height: 0px;
        margin-right: 65px;
        margin-bottom: 50px;
    }

    .content > p:nth-of-type(3n+3) {
        margin-right: 0 !important;
    }

    .fond-text {
        padding-bottom: 30px;
    }

    .boy_def {
        text-align: center;
        margin-top: 65px;
    }

    .boy_def1 {
        width: 265px;
        height: 265px;
    }

    .fond-subtitle {
        color: #BFC2CC;
        font-size: 24px;
        margin-top: 28px;
        text-align: center;
    }

    .fond-title {
        text-align: center;
        color: #0A1C33;
        font-size: 36px;
        margin-top: 77px;
    }
</style>